import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=d969ea14&scoped=true&"
import script from "./Description.vue?vue&type=script&lang=ts&"
export * from "./Description.vue?vue&type=script&lang=ts&"
import style0 from "./Description.vue?vue&type=style&index=0&id=d969ea14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d969ea14",
  null
  
)

export default component.exports
import {QItem,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QIcon})
