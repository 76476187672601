



































import { Component, Vue } from 'vue-property-decorator'
import IconInfo from '@/components/UI/icons/IconInfo.vue'

interface Item {
  count: number
  title: string
}

@Component({
  components: {
    DescriptionItem: () => import('./DescriptionItem.vue'),
    IconInfo,
  },
})
export default class Description extends Vue {
  get items (): Item[] {
    return [{
      count: 1,
      title: this.$t('import.step1.description.1').toString(),
    }, {
      count: 2,
      title: this.$t('import.step1.description.2').toString(),
    }, {
      count: 3,
      title: this.$t('import.step1.description.3').toString(),
    }, {
      count: 4,
      title: this.$t('import.step1.description.4').toString(),
    }, {
      count: 5,
      title: this.$t('import.step1.description.5').toString(),
    }, {
      count: 6,
      title: this.$t('import.step1.description.6').toString(),
    }, {
      count: 7,
      title: this.$t('import.step1.description.7').toString(),
    }]
  }
}
